import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { auth } from '../utils/firebaseConfig';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Row, Col, Image, Button, OverlayTrigger, Tooltip, Container, Breadcrumb, Spinner } from 'react-bootstrap';
import Favorite from '@mui/icons-material/Favorite';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import ProductCard from '../components/ProductCard';
import { useTheme } from '../themes';
import Footer from '../components/Footer';

const ProductDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [isLiked, setIsLiked] = useState(false);
  const [likes, setLikes] = useState(0);
  const [canBuy, setCanBuy] = useState(false);
  const [loading, setLoading] = useState(true);
  const [similarProducts, setSimilarProducts] = useState([]);
  const { isDarkTheme } = useTheme();

  useEffect(() => {
    const fetchProductDetails = async () => {
      setLoading(true);
      setProduct(null);
      try {
        const [productResponse, similarProductsResponse] = await Promise.all([
          axios.get(`${process.env.REACT_APP_API_URL}/api/products/${id}`),
          axios.get(`${process.env.REACT_APP_API_URL}/api/products/similar/${id}?limit=10`) // Fetch a larger set of similar products
        ]);

        setProduct(productResponse.data);
        setLikes(productResponse.data.likes);
  
        const user = auth.currentUser;
        if (user) {
          const token = await user.getIdToken();
          const [statusResponse, userResponse] = await Promise.all([
            axios.get(`${process.env.REACT_APP_API_URL}/api/products/${id}/status`, { headers: { Authorization: `Bearer ${token}` } }),
            axios.get(`${process.env.REACT_APP_API_URL}/api/user`, { headers: { Authorization: `Bearer ${token}` } })
          ]);

          const { activeSubscription, hasPurchased } = statusResponse.data;
          setCanBuy(!(activeSubscription || hasPurchased));
          setIsLiked(userResponse.data.likes.some((like) => like._id === id));
        } else {
          setCanBuy(true);
        }

        // Shuffle similar products to get a random selection
        const shuffledProducts = similarProductsResponse.data.sort(() => 0.5 - Math.random());
        setSimilarProducts(shuffledProducts.slice(0, 4)); // Get top 4 random similar products
      } catch (error) {
        console.error('Error fetching product details:', error.response?.data || error.message);
        toast.error('Error fetching product details.');
      } finally {
        setLoading(false);
      }
    };

    fetchProductDetails();
  }, [id]);
  const formatNumber = (num) => {
    if (typeof num !== 'number') return '0';
    if (num >= 1_000_000_000) return (num / 1_000_000_000).toFixed(num % 1_000_000_000 === 0 ? 0 : 1) + 'B';
    if (num >= 1_000_000) return (num / 1_000_000).toFixed(num % 1_000_000 === 0 ? 0 : 1) + 'M';
    if (num >= 1_000) return (num / 1_000).toFixed(0) + 'K';
    return num.toString();
  };

  const handleLikeToggle = async (event) => {
    event.stopPropagation();
    const user = auth.currentUser;
    if (!user) {
      navigate(`/login?redirect=${window.location.pathname}`);
      return;
    }

    try {
      const token = await user.getIdToken();
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/${product._id}/like`, {}, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const { isLiked: updatedIsLiked, likesCount } = response.data;
      setLikes(likesCount);
      setIsLiked(updatedIsLiked);
    } catch (error) {
      toast.error('Error processing your request.');
    }
  };
  const handleDownload = async (format) => {
    const user = auth.currentUser;
    if (!user) {
        toast.error('You need to be logged in to download products.');
        return;
    }

    try {
        const token = await user.getIdToken();
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/${id}/download`,
            { format },
            { headers: { Authorization: `Bearer ${token}` }, responseType: 'blob' } // Asegúrate de que responseType esté aquí
        );

        const blob = new Blob([response.data], { type: response.headers['content-type'] }); // Usa el tipo de contenido correcto
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(blob);

        // Suponiendo que tienes el título del producto en `item.title`
        const fileName = `${product.title.replace(/[^a-zA-Z0-9]/g, '_')}.${format}`; // Reemplaza caracteres especiales por guiones bajos
        link.href = url;
        link.setAttribute('download', fileName); // Usa el nombre de archivo dinámico
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.error('Error downloading product:', error.message);
        toast.error('Failed to download product.');
    }
};


  const handleBuy = () => {
    if (canBuy) navigate(`/payment/${id}`);
  };

  return (
    <div>
      {loading ? (
        <Container className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
          <Spinner animation="border" variant="primary" />
        </Container>
      ) : product ? (
        <Container className="mt-5 mb-5 pb-5">
          <Breadcrumb className="mt-5 mb-5">
            <Breadcrumb.Item onClick={() => navigate('/')}>Home</Breadcrumb.Item>
            <Breadcrumb.Item onClick={() => navigate('/products')}>Products</Breadcrumb.Item>
            <Breadcrumb.Item active>{product.title}</Breadcrumb.Item>
          </Breadcrumb>
          <Row>
            <Col md={6}>
              <Image style={{ maxWidth: '500px' }} src={product.publicDownloadUrl} alt={product.title} fluid />
            </Col>
            <Col md={6}>
              <h1 className="mb-2">{product.title}</h1>
              <p className="mb-2">{product.description}</p>
              <p className="mb-2">Price: ${product.price}</p>
              <div className="d-flex align-items-center">
                <OverlayTrigger placement="top" overlay={<Tooltip>{isLiked ? 'Unlike' : 'Like'}</Tooltip>}>
                  <Button variant="link" onClick={handleLikeToggle}>
                    {isLiked ? <Favorite /> : <FavoriteBorder />}
                    <span className="ml-2">{formatNumber(likes)}</span>
                  </Button>
                </OverlayTrigger>
                <Button variant="secondary" onClick={() => handleDownload('png')} style={{ margin: '10px', width: '30%' }}>
                  Download PNG
                </Button>
                {!canBuy && (
                  <Button variant="primary" onClick={() => handleDownload('svg')} style={{ margin: '10px', width: '30%' }}>
                    Download SVG
                  </Button>
                )}
              </div>
              <br />
              {canBuy && (
                <Col md={5}>
                  <Button variant="primary" className={`${isDarkTheme ? 'dark-theme' : 'light-theme'}`} onClick={handleBuy}>
                    Shop now ${product.price}
                  </Button>
                </Col>
              )}
            </Col>
            <br />
          </Row>
        </Container>
      ) : (
        <p>Error loading product details.</p>
      )}
      <Col className='bg-header-home pt-5'>
        <Container>
          <br />
          <h2 className="my-4">Other Similar Results</h2>
          <Row>
            {similarProducts.map((product, index) => (
              <Col xs={12} sm={6} md={4} lg={3} key={`${product._id}-${index}`} className="mb-4">
                <ProductCard product={product} />
              </Col>
            ))}
          </Row>
        </Container>
      </Col>
      <Footer />

    </div>
  );
};

export default ProductDetail;